
import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {Ticket, Item} from '../store/types'

const itemAmount = (item: Item) => {
  const gross = item.gross || 0
  const tare = item.tare || 0
  const net = gross - tare
  const deduction = item.deduction || 0
  const weight = net - deduction
  const contamination = item.contamination || 0.0
  const scale = (1 - contamination)
  const price = item.price || 0.0
  const multiplier = item.unit?.multiplier || 1
  const charge = item.charge || 0.0
  return weight * scale * price / multiplier - charge
}

@Component({
  apollo: {
    $subscribe: {
      tickets: {
        query: gql`
          subscription getOpenTickets {
            ticket(where: {status: {_eq: open}}) {
              id
              serial_number
              status
              description
              created_at
              items {
                id
                gross
                tare
                price
                contamination
                deduction
                charge
                unit
                commodity {
                  name
                }
              }
              customer {
                given_names
                family_name
              }
            }
          }
        `,
        result ({ data } : { data: { ticket: Ticket[] }}) {
          this.tickets = data.ticket
        }
      }
    }
  }
})

export default class Dashboard extends Vue {
  tickets = []

  ticketTotal(ticket: Ticket) {
    return ticket.items.reduce(
      (total : number, item: Item) =>
        itemAmount(item) + total, 0)
  }
}
