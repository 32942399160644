<template>
  <v-app-bar
      id="app-bar"
      app
      color="primary"
      class="v-bar--underline"
  >
    <v-app-bar-nav-icon
        @click="drawer = !drawer"
    />

    <router-link to="/" class="d-inline-flex align-center logo">
      <v-img
          alt="Scrap Tickets Logo"
          src="@/assets/logo.png"
          width="34"
          height="34"
      />
      <v-toolbar-title class="black--text logo_name">Scrap Tickets</v-toolbar-title>
    </router-link>

    <app-bar-items/>

    <v-spacer/>

    <v-btn outlined v-if="$auth.isAuthenticated" @click="logout">Logout</v-btn>
    <v-btn outlined v-else @click="login">Login</v-btn>

  </v-app-bar>
</template>

<script>
import AppBarItems from "@/components/AppBarItems";
import { sync } from 'vuex-pathify'

export default {
  name: "AppBar",
  components: {AppBarItems},
  computed: {
    drawer: sync('app/drawer')
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
  },
}
</script>

<style scoped>
.logo {
  text-decoration: none;
}

.logo_name {
  color: black;
  font-size: 2em;
  font-family: 'Rubik Mono One';
  font-weight: bold;
}
</style>