import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {split} from 'apollo-link'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'
import {WebSocketLink} from 'apollo-link-ws'
import {SubscriptionClient} from 'subscriptions-transport-ws'
import {getMainDefinition} from 'apollo-utilities'
import {setContext} from 'apollo-link-context'
import {getInstance} from "./auth/index";

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
// const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS

const getAuth = async () => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const authService = getInstance();
  if (!authService.isAuthenticated) {
    return ''
  }
  const token = await authService.getTokenSilently();
  if (token) {
    return 'Bearer ' + token
  } else {
    return ''
  }
}

// Call this in the Vue app file
export function createProvider () {
  const httpLink = createUploadLink({
    uri: httpEndpoint,
  })
  // HTTP Auth header injection
  const authLink = setContext(async (_, { headers }) => {
    const Authorization = await getAuth()
    const authorizationHeader = Authorization ? { Authorization } : {}
    return {
      headers: {
        ...headers,
        ...authorizationHeader,
      },
    }
  })
  const wsClient = new SubscriptionClient(wsEndpoint, {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      return {
        headers: {
          Authorization: await getAuth()
        }
      }
    },
  })
  const wsLink = new WebSocketLink(wsClient)

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' &&
        operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLink),
  )
  // Create apollo client
  const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })
}
