import Vue from 'vue'
import Vuex from 'vuex'

// pathify config
import pathify from './pathify'
import app from './modules/app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app
  },
  plugins: [ pathify.plugin ]
})
