<template>
  <div class="d-flex align-center">
    <!-- <v-btn to="tickets" plain v-if="$auth.isAuthenticated">Tickets</v-btn> -->
  </div>
</template>

<script>
export default {
  name: "AppBarItems",
}
</script>