import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#138A36',
        secondary: '#C2BBF0',
        //accent: '#82B1FF',
        error: '#d00036',
        //info: '#2196F3',
        //success: '#4CAF50',
        //warning: '#FFC107',
      },
      dark: {
      },
    },
  },
});
