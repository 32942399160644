
import { Vue, Component, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'

interface AccountInfo {
  address1: string,
  address2: string,
  country: string,
  created_at: string,
  email: string,
  name: string,
  phone_number: string,
  postcode: string,
  town: string,
  unit: string,
  stripe_id: string,
  signature_message: string,
  rounding_interval: number,
  region: string,
  id: string
}

@Component({
  apollo: {
    account: gql`query {
      account {
        address1
        address2
        country
        created_at
        email
        name
        phone_number
        postcode
        town
        unit
        stripe_id
        signature_message
        rounding_interval
        region
        id
      }
    }`,
  },
})

export default class Account extends Vue {
  accountInfo = {} as AccountInfo
  updatingAccountInfo = false

  get unitOptions () {
    return [
      {text: "Pound", value: "lb"},
      {text: "Kilogram", value: "kg"},
    ]
  }

  @Watch('account')
  onAccountChange (newVal: AccountInfo[]) {
    this.accountInfo = newVal[0]
  }

  updateAccountInfo () {
    this.updatingAccountInfo = true
    this.$apollo.mutate({
      mutation: gql`
        mutation ($id: uuid!, $object: account_set_input!) {
          update_account_by_pk(
            _set: $object,
            pk_columns: {id: $id}
          ) {
            id
          }
      }`,
      variables: {
        id: this.accountInfo.id,
        object: {
          address1: this.accountInfo.address1,
          address2: this.accountInfo.address2,
          country: this.accountInfo.country,
          email: this.accountInfo.email,
          name: this.accountInfo.name,
          phone_number: this.accountInfo.phone_number,
          postcode: this.accountInfo.postcode,
          town: this.accountInfo.town,
          region: this.accountInfo.region,
        },
      },
    })
    .finally(() => this.updatingAccountInfo = false)
  }
}
