import { getInstance } from "./index";
import jwt_decode from "jwt-decode";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    if (to.name === "callback") {
      return next()
    }
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      // If they aren't trying to register, make sure the user is registered
      const token = await authService.getTokenSilently()
      console.log(token)
      const claims = jwt_decode(token)
      console.log(claims)
      const userId = claims["https://api.scraptickets.com/user_id"]
      console.log("page: " + to.name)
      const subscribed = claims["https://api.scraptickets.com/valid_subscription"]
      if (to.name !== "register") {
        if (!userId || !subscribed) {
          console.log("redirecting to register")
          return next('register')
        }
      }
      return next()
    }
    console.log("user is not authenticated")

    // Otherwise, log in
    const options = {
      appState: {
        targetUrl: to.fullPath
      },
    }

    // if forcing login from register page, show signup form
    if (to.name === "register") {
      options.screen_hint = "signup"
    }

    authService.loginWithRedirect(options);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
