<template>
  <v-app>
    <app-bar />

    <drawer />

    <v-main class="grey lighten-3">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import Drawer from "@/components/Drawer";
export default {
  name: 'App',
  components: {Drawer, AppBar},
}
</script>

<style>
/* This should be done in a global style file */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
