import Vue from "vue"
import moment from 'moment'

Vue.mixin({
    methods: {
        formatDate(timestamp) {
            return moment.unix(timestamp).format('MMMM Do, YYYY')
        },
        formatDateTime(dateString) {
            return new Intl.DateTimeFormat('en', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            }).format(new Date(dateString))
        },
        formatCurrency(amount, currency) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
            })
            return formatter.format(amount)
        },
    },
})
