<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
            cols="6"
            sm="12"
            class="mx-4"
            label="Start Date"
            v-model="startDate"
        />
      </v-col>
      <v-col>
        <v-text-field
            cols="6"
            sm="12"
            class="mx-4"
            label="End Date"
            v-model="endDate"
        />
      </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="purchasedItems"
        group-by="category"
        disable-pagination
        hide-default-footer
        fixed-header
        height="90vh"
    >
      <template v-slot:[`item.totalWeight`]="{ item }">
        {{ new Intl.NumberFormat().format(item.totalWeight) }}
      </template>
      <template v-slot:[`item.averagePrice`]="{ item }">
        {{ formatCurrency(item.averagePrice, 'usd') }}
      </template>
      <template v-slot:[`item.totalAmount`]="{ item }">
        {{ formatCurrency(item.totalAmount, 'usd') }}
      </template>
    </v-data-table>
    <v-row>
      <v-col>Total:</v-col>
      <v-col>{{ formatCurrency(total, 'usd') }}</v-col>
    </v-row>
  </div>
</template>

<script>
import {Vue, Component} from 'vue-property-decorator'
import gql from 'graphql-tag'
import _ from 'lodash'

const objectMap = (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
    )

const itemCalc = (item) => {
  const net = (item.gross ?? 0) - (item.tare ?? 0)
  const scale = 1 - (item.contamination ?? 0)
  const weight = net - (item.deduction ?? 0)
  const mul = item.unit_multiplier.multiplier
  const amount = weight * scale * (item.price ?? 0) / mul - (item.charge ?? 0)
  return {net, weight, amount}
}

@Component({
  apollo: {
    items: {
      query: gql`
        query getPaidItems($start: timestamptz!, $end: timestamptz!) {
          item(
              where: {
                _and: [
                   {ticket: {status: {_eq: paid}}},
                   {ticket: {created_at: {_gte: $start}}},
                   {ticket: {created_at: {_lte: $end}}},
                   {deleted_at: {_is_null: true}}
                ]
               }
              ) {
            price
            gross
            tare
            charge
            contamination
            deduction
            unit
            unit_multiplier {
              multiplier
            }
            commodity {
              name
              category {
                name
              }
            }
          }
        }
      `,
      update: data => data.item,
      variables() {
        return {
          // TODO: use local timezone here
          start: this.startDate + "T00:00:00+00:00",
          end: this.endDate + "T23:59:59+00:00",
        }
      }
    },
  },
})

export default class PurchaseSummary extends Vue {
  data() {
    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    return {
      endDate: currentDate.toISOString().substring(0, 10),
      startDate: oneMonthAgo.toISOString().substring(0, 10),
    }
  }

  get purchasedItems() {
    const embellished = (this.items ?? []).map((item) => {
      return {...item, ...itemCalc(item)}
    })
    const grouped = _.groupBy(embellished, (item) => item.commodity?.name)
    const aggregate = objectMap(grouped, (items) => {
      const commodity = items[0].commodity?.name ?? "(Unspecified)"
      const category = items[0].commodity?.category?.name ?? "None"
      const totalAmount = _.sumBy(items, (item) => item.amount)
      const totalWeight = _.sumBy(items, (item) => item.weight)
      return {
        commodity: commodity,
        category: category,
        totalWeight: totalWeight,
        averagePrice: totalAmount / totalWeight,
        totalAmount: totalAmount,
      }
    })
    return Object.values(aggregate).flat()
  }

  get total() {
    return (this.items ?? []).reduce(
        (partialSum, item) => {
          return partialSum + itemCalc(item).amount
        },
        0
    )
  }

  get headers() {
    return [
      {text: "Category", value: "category"},
      {text: "Commodity", value: "commodity"},
      {text: "Net Weight", value: "totalWeight", align: 'right'},
      {text: "Avg. Price", value: "averagePrice", align: 'right'},
      {text: "Amount", value: "totalAmount", align: 'right'},
    ]
  }

}
</script>
