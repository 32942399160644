<template>
  <v-col>
    <v-row>
      <v-text-field
          cols="6"
          sm="12"
          class="mx-4"
          label="Start Date"
          v-model="startDate"
      />
      <v-text-field
          cols="6"
          sm="12"
          class="mx-4"
          label="End Date"
          v-model="endDate"
      />
    </v-row>
    <v-data-table
        :headers="headers"
        :items="totals"
        disable-pagination
        hide-default-footer
        fixed-header
        height="90vh"
    >
      <template v-slot:[`item.total`]="{ item }">
        {{ formatCurrency(item.total, 'USD') }}
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

const calcAmount = (item) => {
  const net = (item.gross??0) - (item.tare??0)
  const scale = 1 - (item.contamination??0)
  const weight = net - (item.deduction??0)
  const mul = item.unit_multiplier.multiplier
  return weight * scale * (item.price??0) / mul - (item.charge??0)
}

function parseDate(date) {
  const [year, month] = date.split('-');
  return new Date(year, month - 1);
}

@Component({
  apollo: {
    totals: {
      query: gql`
        query getPaidItems($start: timestamptz!, $end: timestamptz!) {
          item(
              where: {
                _and: [
                   {ticket: {status: {_eq: paid}}},
                   {ticket: {created_at: {_gte: $start}}},
                   {ticket: {created_at: {_lt: $end}}},
                   {deleted_at: {_is_null: true}}
                ]
               }
              ) {
            price
            gross
            tare
            charge
            contamination
            deduction
            unit
            unit_multiplier {
              multiplier
            }
            created_at
          }
        }
      `,
      update(data) {
        const results = [];
        const currMonth = parseDate(this.startDate);
        const endDate = parseDate(this.endDate);
        endDate.setUTCMonth(endDate.getUTCMonth() + 1)
        while (currMonth < endDate) {
          let sum = 0.0;
          const endOfMonth = new Date(currMonth.getUTCFullYear(), currMonth.getUTCMonth() + 1, 1);
          for (const item of data.item) {
            const createdAt = new Date(item.created_at);
            if (createdAt >= currMonth && createdAt < endOfMonth) {
              sum += calcAmount(item);
            }
          }
          results.push({ date: currMonth.getUTCFullYear() + "-" + (currMonth.getUTCMonth() + 1), total: sum });
          currMonth.setUTCMonth(currMonth.getUTCMonth() + 1)
        }
        return results;
      },
      variables () {
        const start = parseDate(this.startDate)
        const end = parseDate(this.endDate);
        end.setMonth(end.getMonth() + 1)
        return {
          start: start.toISOString(),
          end: end.toISOString(),
        }
      }
    },
  },
})

export default class PurchaseTotals extends Vue {
  data() {
    const currentDate = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setMonth(currentDate.getMonth() - 11);

    return {
      endDate: currentDate.toISOString().substring(0, 7),
      startDate: oneYearAgo.toISOString().substring(0, 7),
    }
  }

  get headers () {
    return [
      { text: "Date", value: "date" },
      { text: "Total", value: "total", align: 'right' },
    ]
  }

}
</script>
