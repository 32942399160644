
import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'

@Component({
  apollo: {
    ticket: gql`query {
      ticket {
        items {
          id
          gross
          price
          commodity {
            name
          }
        }
      }
    }`,
  }
})
export default class Tickets extends Vue {
}
