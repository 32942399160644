import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth/authGuard";

import Dashboard from '@/views/Dashboard.vue'
import Tickets from '@/views/Tickets.vue'
import Subscribe from '@/views/Subscribe.vue'
import Register from '@/views/Register.vue'
import Registered from '@/views/Registered.vue'
import Callback from '@/views/Callback.vue'
import Billing from '@/views/Billing.vue'
import Account from '@/views/Account.vue'
import PurchaseSummary from '@/views/PurchaseSummary.vue'
import PurchaseTotals from '@/views/PurchaseTotals.vue'
import Prices from '@/views/Prices.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/tickets",
    name: "tickets",
    component: Tickets,
    beforeEnter: authGuard,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscribe,
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: authGuard,
  },
  {
    path: "/registered",
    name: "registered",
    component: Registered,
  },
  {
    path: "/callback",
    name: 'callback',
    component: Callback,
  },
  {
    path: "/billing",
    name: 'billing',
    component: Billing,
    beforeEnter: authGuard,
  },
  {
    path: "/account",
    name: 'account',
    component: Account,
    beforeEnter: authGuard,
  },
  {
    path: "/purchase-summary",
    name: "purchase-summary",
    component: PurchaseSummary,
    beforeEnter: authGuard,
  },
  {
    path: "/purchase-totals",
    name: "purchase-totals",
    component: PurchaseTotals,
    beforeEnter: authGuard,
  },
  {
    path: "/prices",
    name: "prices",
    component: Prices,
    beforeEnter: authGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
