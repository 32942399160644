<template>
    <div class="callback">
        <h1>Logging in...</h1>
        <div v-if="error">{{ error }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: null
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.query?.error) {
            console.log("Error: " + to.query.error)
            console.log("Message: " + to.query.error_description)
            this.error = to.query.error_description
        }
        next()
    },
}
</script>