import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'
import { Auth0Plugin } from "./auth"
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'
import "./mixins/utils"

Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState:any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : "/"
    )
  }
})

Vue.use(VuetifyConfirm, {
  vuetify
})

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: h => h(App)
}).$mount('#app')
