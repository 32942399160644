<template>
  <v-navigation-drawer
      v-model="drawer"
      app
      floating
      width="300"
  >
    <v-list-item>
      <v-img
          alt="Scrap Tickets Logo"
          src="@/assets/logo.png"
          width="200"
          height="200"
      />
    </v-list-item>
    <v-list-item>
      <v-toolbar-title class="black--text logo_name">Scrap Tickets</v-toolbar-title>
    </v-list-item>

    <v-list dense nav>
      <template v-if="$auth.isAuthenticated">
        <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="prices">
          <v-list-item-content>
            <v-list-item-title>Prices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="purchase-summary">
          <v-list-item-content>
            <v-list-item-title>Purchase Summary</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="purchase-totals">
          <v-list-item-content>
            <v-list-item-title>Purchase Totals</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="account">
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="Billing">
          <v-list-item-content>
            <v-list-item-title>Billing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import {sync} from 'vuex-pathify'

export default {
  name: "Drawer",
  computed: {
    drawer: sync('app/drawer')
  },
}
</script>