<template>
  <v-container>
    <h1>Register Organization</h1>
    <div id="register-form">
      <v-text-field v-model="userName" label="Your full name"/>
      <v-text-field v-model="businessName" label="Business name"/>
      <v-select
          label="Base unit of measurement"
          :items="unitOptions"
          v-model="unit"
      />
      <v-select
          label="Currency"
          :items="currencyOptions"
          v-model="currency"
      />

      <div id="message" role="note">{{ message }}</div>
      <div id="card-error" role="alert">{{ error }}</div>
      <v-btn @click="registerAccount" :disabled="processing" color="primary">Submit</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
      businessName: "",
      unit: 'lb',
      currency: 'USD',
      error: null,
      message: null,
      processing: false,
    }
  },
  computed: {
    unitOptions() {
      return [
        {text: "Pound", value: "lb"},
        {text: "Kilogram", value: "kg"},
      ]
    },
    currencyOptions() {
      return [
        {text: "US dollar", value: "USD"},
        {text: "Canadian dollar", value: "CAD"},
        {text: "Euro", value: "EUR"},
        {text: "Pound sterling", value: "GBP"}
      ]
    }
  },
  methods: {
    registerAccount() {
      this.processing = true
      this.error = null
      this.message = "Registering account. Please wait, this process may take up to 30 seconds."
      this.$auth.getTokenSilently().then((token) => {
        const url = process.env.VUE_APP_REST_URL_BASE + "register"
        console.log("endpoint: " + url)
        fetch(url, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            businessName: this.businessName,
            userName: this.userName,
            unit: this.unit,
            currency: this.currency,
          }),
        })
            .then((response) => {
              console.log(response)
              if (response.status === 200) {
                console.log("reloading token")
                this.$auth.loginWithRedirect({
                  appState: {targetUrl: "/registered"}
                })
              } else {
                this.processing = false
                this.message = null
                this.error = "There was an error processing your registration. Please contact support at support@scraptickets.com"
                response.json().then((error) => {
                  console.log(error)
                  this.error = error.error
                })
              }
            })
            .catch((error) => {
              console.log(error)
              this.error = "There was an error processing your registration. Please contact support at support@scraptickets.com"
              this.message = null
              this.processing = false
            })
      })
    }
  },
}
</script>
