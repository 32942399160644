<template>
  <v-container pt-4>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title><span class="text-h5">Billing Details</span></v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="text-h6">Current balance</v-row>
              <v-row>
                {{ balance }}
              </v-row>
              <v-row class="text-h6">Payment method</v-row>
              <v-row>
                <v-col md-cols="8">{{ paymentInfo }}</v-col>
                <v-col md-cols="4">
                  <v-dialog v-model="paymentDialog">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary">Update</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Update payment method</v-card-title>
                      <v-card-text>
                        <v-container>
                          <stripe-element-card
                            ref="elementRef"
                            :pk="publishableKey"
                          />
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="paymentDialog = false">Cancel</v-btn>
                        <v-btn text @click="updatePaymentMethod()" :disabled="processing">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn plain @click="cancel" color="error">Cancel Account</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Invoices</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="invoiceHeaders"
              :items="invoices"
              sort-by="created"
              sort-desc
              disable-pagination
              hide-default-footer
              >
              <template v-slot:[`item.id`]="{item}">
                <v-btn icon :href="item.invoice_pdf">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.created`]="{item}">
                {{ formatDate(item.created) }}
              </template>
              <template v-slot:[`item.total`]="{item}">
                {{ formatCurrency(item.total, item.currency) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { StripeElementCard } from '@vue-stripe/vue-stripe'

@Component({
  components: {
    StripeElementCard,
  },
})
export default class Prices extends Vue {
  publishableKey = process.env.VUE_APP_STRIPE_KEY
  customer = null
  invoices = []
  card = null
  loading = true
  paymentMethod = null
  paymentDialog = false
  processing = false

  get balance () {
    if (!this.loading) {
      return this.formatCurrency(this.customer.balance, this.customer.currency)
    } else {
      return "Loading ..."
    }
  }

  get paymentInfo () {
    if (!this.loading) {
      if (this.paymentMethod) {
        return "**** **** **** " + this.paymentMethod.card.last4 +
            " expires: " + this.paymentMethod.card.exp_month + "/" + this.paymentMethod.card.exp_year
      } else {
        return "No current payment method"
      }
    } else {
      return "Loading ..."
    }
  }

  get invoiceHeaders () {
    return [
      { text: "Invoice", value: "id", sortable: false },
      { text: "Created", value: "created" },
      { text: "Amount", value: "amount" },
    ]
  }

  mounted () {
    this.loadBillingData()
  }

  loadBillingData () {
    this.$auth.getTokenSilently().then((token) => {
      const url = process.env.VUE_APP_REST_URL_BASE + "billing"
      fetch(url, {
        method: "GET",
        mode: 'cors',
        headers: {
          'authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      }).then(response => {
        response.json().then(value => {
          this.customer = value.customer
          this.invoices = value.invoices
          this.paymentMethod = value.paymentMethod
          this.loading = false
        })
      })
    })
  }

  cancel () {
    const msg = "This will cancel your subscription. Are you sure?"
    this.$confirm(msg).then(res => {
      if (res) {
        this.$auth.getTokenSilently().then((token) => {
          const url = process.env.VUE_APP_REST_URL_BASE + "cancel"
          fetch(url, {
            method: "POST",
            mode: 'cors',
            headers: {
              'authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
            body: {},
          }).then(() => {
            this.loadBillingData()
          })
        })
      }
    })
  }

  updatePaymentMethod () {
    this.processing = true

    const element = this.$refs.elementRef.element
    const stripe = this.$refs.elementRef.stripe
    stripe.createPaymentMethod({
      type: 'card',
      card: element,
    })
    .then((result) => {
      console.log(result)
      if (result.error) {
        const errorElement = document.getElementById('stripe-element-errors');
        errorElement.textContent = result.error.message;
        return;
      }
      console.log(result.paymentMethod);
        
      const url = process.env.VUE_APP_REST_URL_BASE + "update-payment"
      this.$auth.getTokenSilently()
      .then((token) => {
        const data = {
          paymentMethodId: result.paymentMethod.id
        }
        console.log(data)
        fetch(url, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("payment updated successfully")
            this.paymentDialog = false
          } else {
            console.log("something went wrong!")
            response.json().then((value) => {
              console.log(value)
            })
          }
          this.processing = false
          this.loadBillingData()
        })
      })
    })
    .catch((error) => {
      const errorElement = document.getElementById('stripe-element-errors');
      errorElement.textContent = error.message;
      this.processing = false
    })
  }
}
</script>

<style scoped>
.unpaid {
  font-weight: bold;
}
</style>
