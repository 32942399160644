
import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { v4 as uuidv4 } from 'uuid'

interface PriceRow {
  commodity_id: string,
  price_list_id: string,
  commodity: { name: string, category: { name: string }},
  price: number,
  category?: string,
}

@Component({
  components: {},
  props: {},
  apollo: {
    $subscribe: {
      prices: {
        query: gql`
          subscription getPrices {
            price (
              where: {
                _and: [
                  { price_list: { name: {_eq: ""} } },
                  { commodity: { deleted_at: {_is_null: true} } },
                ]
              },
              distinct_on: [price_list_id, commodity_id],
              order_by: [
                { price_list_id: asc },
                { commodity_id: asc },
                { created_at: desc }
              ]
            ) {
              price_list_id
              commodity_id
              price
              commodity {
                name
                category {
                  name
                }
              }
            }
          }
        `,
        result ({ data } : { data : { price: PriceRow[] }}) {
          data.price.forEach(price => price.category = price.commodity.category.name)
          this.priceList = data.price
        }
      },
    }
  },
})

export default class Prices extends Vue {
  priceList = []
  showDialog = false
  updating = false
  editRow: PriceRow | null = null
  newPrice = 0

  get headers () {
    return [
      { text: "Category", value: "category" },
      { text: "Commodity", value: "commodity.name" },
      { text: "Price", value: "price", align: 'right' },
    ]
  }

  openPriceDialog (row : PriceRow) {
    this.editRow = row
    this.newPrice = row.price
    this.showDialog = true
  }

  update () {
    this.updating = true
    this.$apollo.mutate({
      mutation: gql`
        mutation priceInsert ($object: price_insert_input!) {
          insert_price_one (object: $object) {
            id
          }
        }
      `,
      variables: {
        object: {
          id: uuidv4(),
          commodity_id: this.editRow?.commodity_id,
          price_list_id: this.editRow?.price_list_id,
          price: this.newPrice,
        },
      },
    }).then((data) => { console.log(data); this.showDialog = false })
    .catch((err) => console.log(err))
    .finally(() => this.updating = false)
  }

  cancel () {
    this.showDialog = false
  }
}
