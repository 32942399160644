<template>
  <v-container>
    <div class="complete" v-if="completed">
      Your subscription was successfully started. <a href="https://www.scraptickets.com/download">Download app</a>
    </div>
    <div v-else>
      <h1>Scrap Tickets Subscription</h1>
      <h3>$50/month + $0.10/ticket after 500</h3>
      <header>Includes:</header>
      <ul>
        <li>30 day free trial</li>
        <li>500 tickets per month</li>
        <li>
          Images of purchased items, drivers licenses, vehicles, and signatures
        </li>
        <li>Android app</li>
        <li>iScrap App and website integration</li>
        <li>Bluetooth receipt printing</li>
        <li>Drivers license barcode scanning</li>
        <li>Builtin signature pad</li>
      </ul>
      <stripe-element-card
        ref="elementRef"
        :pk="publishableKey"
        />
      <v-btn color="primary" @click="submitPayment" :disabled="processing">Submit</v-btn>
    </div>
  </v-container>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe'

export default {
  name: "Subscribe",
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_KEY
    return {
      completed: false,
      processing: false,
    };
  },
  components: {
    StripeElementCard,
  },
  methods: {
    submitPayment() {
      if (this.processing || this.completed)
        return
      this.processing = true
      const stripe = this.$refs.elementRef.stripe
      const card = this.$refs.elementRef.element
      stripe.createPaymentMethod({
        type: 'card',
        card: card,
      })
      .then((result) => {
        if (result.error) {
          // handle error here
          const errorElement = document.getElementById('stripe-element-errors');
          errorElement.textContent = result.error.message;
          return;
        }
        console.log(result.paymentMethod);
        
        const url = process.env.VUE_APP_REST_URL_BASE + "subscribe"
        this.$auth.getTokenSilently()
          .then((token) => {
            console.log("token in subscribe: " + token)
            const data = {
              paymentMethodId: result.paymentMethod.id
            }
            console.log(data)
            fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
              })
              .then((response) => {
                if (response.status == 200) {
                  response.json().then((value) => {
                    console.log("subscribed successfully")
                    console.log(value)
                    this.completed = true
                  })
                } else {
                  console.log("something went wrong!")
                  response.json().then((value) => {
                    console.log(value)
                    const errorElement = document.getElementById('stripe-element-errors')
                    errorElement.textContent = value.error
                  })
                }
              })
          })
      })
      .catch((error) => {
        const errorElement = document.getElementById('stripe-element-errors')
        errorElement.textContent = error.message
      })
      .finally(() => {
        this.processing = false
      })
    },
  },
};
</script>
